import 'jquery-captcha';


window.refreshCaptchaContact = () => {
    $('#frontal').remove();
    $('.btn-refresh').remove();
    $('.captcha_contact').each(function() {
        $(this).captcha({
            captchaEndpoint: '/captcha/simple-captcha-endpoint'
        });
    });
    event.preventDefault();
}

window.refreshCaptchaNewsletter = () => {
    $('#frontal').remove();
    $('.btn-refresh').remove();
    $('.captcha_newsletter').each(function() {
        $(this).captcha({
            captchaEndpoint: '/captcha/simple-captcha-endpoint'
        });
    });
    event.preventDefault();
}


$(document).on('turbolinks:load', function() {
    $('.captcha').each(function() {
        $(this).captcha({
            captchaEndpoint: '/captcha/simple-captcha-endpoint'
        });
    });

    $('.modal-contact').each(function() {
        $(this).on('click', function() {
            refreshCaptchaContact();
        });
    });

    $('.modal-newsletter').each(function() {
        $(this).on('click', function() {
            refreshCaptchaNewsletter();
        });
    });
});
