document.addEventListener('turbolinks:load', function () {

    let searchInput = document.querySelector('input[id=search-input]');
    let searchInputNav = document.querySelector('input[id=search-input-nav]');
    let checkboxes = document.querySelectorAll('input[type=checkbox][name="filter[]"]');
    let moreResults = document.querySelectorAll('.more-results');
    let timeout = null;


// Functions

    const updateResults = (newUrl) => {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            fetch(newUrl)
                .then(response => response.text())
                .then(data => {
                    let parser = new DOMParser();
                    let htmlDocument = parser.parseFromString(data, 'text/html');
                    let newResultsElement = htmlDocument.querySelector('.search .search-results');

                    if (newResultsElement) {
                        let resultsElements = document.querySelectorAll('.search-results');
                        resultsElements.forEach(element => {
                            element.innerHTML = newResultsElement.innerHTML;
                        });
                    } else {
                        console.error('Element .search-results not found in the new data');
                    }
                })
                .catch(error => console.error('Error:', error));
        }, 200);

        return true;
    }

    const updateMoreResultsLink = (newUrl) => {
        moreResults.forEach(function (element) {
            element.href = newUrl;
        });
    };


    const showSearchWindow = () => {
        let searchWindow = document.querySelector('.search-window');
        searchWindow.classList.add('search-window-visible');
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const hideSearchWindow = () => {
        let searchWindow = document.querySelector('.search-window');
        searchWindow.classList.remove('search-window-visible');
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

// Search Title

    if (searchInput) {
        searchInput.addEventListener('input', function () {
            let title = searchInput.value;
            let params = new URLSearchParams(window.location.search);
            params.set('title', title);
            let newUrl = `${window.location.pathname}?${params}`;
            window.history.replaceState({}, '', newUrl);

            updateResults(newUrl);
        });
    }
    if (searchInputNav) {
        searchInputNav.addEventListener('input', function () {
            let title = searchInputNav.value;

            let params = new URLSearchParams(window.location.search);
            params.set('title', title);
            let newUrl = `/recherche?${params}`;
            window.history.replaceState({}, '', newUrl);

            updateResults(newUrl);
            updateMoreResultsLink(newUrl);

            sleep(800).then(() => {
                title.length > 0 ? showSearchWindow() : hideSearchWindow();
            });
        });
    }


// Search Filters

    checkboxes.forEach(function (checkbox) {
        checkbox.addEventListener('change', function () {
            let checkedValues = Array.from(checkboxes)
                .filter(checkbox => checkbox.checked)
                .map(checkbox => checkbox.value);

            let params = new URLSearchParams(window.location.search);
            params.delete('filter[]');
            checkedValues.forEach(value => params.append('filter[]', value));
            let newUrl = `${window.location.pathname}?${params}`;
            window.history.replaceState({}, '', newUrl);

            updateResults(newUrl);
        });
    });
});